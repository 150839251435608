$.fn.ratioSize.defaults.minWindowWidth = 100;

var changePieces = function(size) {
    var getSize = function(elm, key) {
        return $(elm).data('pieces-size-map')[key];
    };
    $('path.responsive').each(function() {
        var actualSize = getSize(this, size);
        var $svg = $(this).closest('svg');
        $(this).attr('d', $(this).data('pieces-' + actualSize));
        // $svg.find('.clip-path').attr('d', $(this).data('pieces-' + actualSize));
        $image = $svg.find('image');
        $image.attr('xlink:href', $(this).data('bgimage-' + actualSize));
        $image.attr('clip-path', 'url(#clip' + $(this).data('clip-path-base') + '-' + actualSize+ ')');
        $(this).closest('.puzzle-piece-wrapper').removeClass('wi wo ni no ei eo si so').addClass($(this).data('classes-' + actualSize));
    });
};

// set up our states
ssm.addState({
    id: 'xsmall',
    maxWidth: 319,
    onEnter: function() {
        changePieces('xsmall');
    }
});

ssm.addState({
    id: 'small',
    minWidth: 320,
    maxWidth: 479,
    onEnter: function() {
        changePieces('small');
    }
});

ssm.addState({
    id: 'medium',
    minWidth: 480,
    maxWidth: 767,
    onEnter: function() {
        changePieces('medium');
    }
});
ssm.addState({
    id: 'large',
    minWidth: 768,
    maxWidth: 959,
    onEnter: function() {
        changePieces('large');
    }
});

ssm.addState({
    id: 'xlarge',
    minWidth: 960,
    maxWidth: 1199,
    onEnter: function() {
        changePieces('xlarge');
    }
});
ssm.addState({
    id: 'xxlarge',
    minWidth: 1200,
    onEnter: function() {
        changePieces('xxlarge');
    }
});

$(document).ready(function(){
    ssm.ready();
});
var $buoop = {vs:{i:8,f:-8,o:-8,s:8,c:-8},unsupported:false,mobile:false,api:4}; 
function $buo_f(){ 
 var e = document.createElement("script"); 
 e.src = "//browser-update.org/update.min.js"; 
 document.body.appendChild(e);
};
try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
catch(e){window.attachEvent("onload", $buo_f)}
