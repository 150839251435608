/**
 * this makes any elements which have a data-url attribute and links them to the
 * referenced url
 *
 * @author Dan Klassen <dan@triplei.ca>
 * @example <div data-url="https://www.google.com">this will link to google.com</div>
 * @example <div data-url="https://www.google.com" data-url-new-tab>this will link to google.com in a new tab (via javascript... popup blockers may complain)</div>
 */
;(function ($) {
    var dataUrlHandler = function () {
        $('body').on('click', '[data-url] a', function(e) {
            e.stopPropagation();
        });
        $('body').on('click', '[data-url]', function() {
            if ($(this).data('url-new-tab') !== undefined) {
                window.open($(this).data('url'), '_blank').focus();
            } else {
                window.location = $(this).data('url');
            }
        });
    };
    dataUrlHandler();
})(jQuery);