/**
 * this takes any items on a page with a data-ratio-size attribute and sets their height to a ratio of their width
 *
 * @author Dan Klassen <dan@triplei.ca>
 * @example
 * <div data-ratio-size='1.3333'>content</div>
 */
;(function($){
    $.fn.ratioSize = function(options) {
        var settings = $.extend($.fn.ratioSize.defaults, options);

        return this.each(function(){
            var $this = $(this);
            var ratioSize = function() {
                if ($(window).width() <= settings.minWindowWidth) {
                    $this.height('auto');
                } else {
                    var ratio = $this.data('ratio-size');
                    if(isNaN(ratio)) {
                        ratio = 1;
                    }
                    $this.height($this.width() / ratio);
                }
            };
            var delayedResize = function() {
                $(window).trigger('resize');
            };
            ratioSize();
            $(window).on('resize.ratio-size-items orientationchange.ratio-size-items', ratioSize);
            $(window).on('load', delayedResize);
        });
    };
    $.fn.ratioSize.defaults = {
        'minWindowWidth': 767
    };
    $('[data-ratio-size]').ratioSize();
})(jQuery);