jQuery(document).ready(function($){
    $('body').addClass('js');
    var $menu = $('.primary-navigation-wrapper').first();
    var $menuLink = $('#nav-link');
    var $subMenuTriggers = $('.dropdown-trigger');
    var $stage = $('.stage').first();
    var $close = $('#close-nav');
    $menuLink.click(function() {
        if ($menuLink.hasClass('active')) {
            closeNav();
        } else {
            showNav();
        }
        return false;
    });

    $subMenuTriggers.click(function(){
        var $subMenu = $(this).siblings('ul').first();
        $subMenu.addClass('open').addClass('current');
        $subMenu.slideToggle();
        $menu.find('ul.open').not('.current').each(function(){
            if ($(this) != $subMenu) {
                $(this).slideUp();
            }
        });
        $subMenu.removeClass('current');
    });

    $close.click(function(){
        closeNav();
    });

    var showNav = function() {
        $menuLink.addClass('active');
        $menu.addClass('active');
        $stage.addClass('active');
        $(window).scrollTo(0);
    };

    var closeNav = function() {
        $menuLink.removeClass('active');
        $menu.removeClass('active');
        $stage.removeClass('active');
    };

    var log = function(msg) {
        console.info(msg);
    };
});
