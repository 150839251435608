/**
 * this takes any items on a page with a data-autoheight attribute and makes them the same height as the
 * group they are associated to
 *
 * items can also be grouped by wrapping their group in an element with a data-autoheight-group='group-name' attribute
 *
 * autoheighting can be disabled below a specific window size with the data-autoheight-min-window-width='123' attribute
 *
 * autoheighting can also be scoped to a parent element (which is handy for situations such as a row of repeating items
 * of different height where each row should be scoped together) by wrapping each group with a
 * data-autoheight-container="container-handle" (see example 3)
 *
 * @author Dan Klassen <dan@triplei.ca>
 * @example 1
 * <div data-autoheight='grouping1'>content</div>
 * <div data-autoheight='grouping1'>content<br />of different height</div>
 *
 * @example 2
 * <div data-autoheight-group='grouping1'>
 *     <div data-autoheight>content</div>
 *     <div data-autoheight>content<br />of different height</div>
 * </div>
 *
 * @example 3
 * <div data-autoheight-container="row1">
 *   <div data-autoheight-group="wrapper">
 *     <div data-autoheight-group="title">
 *       <h1>item 1</h1>
 *       <h2>Sub title</h2>
 *     </div>
 *     content
 *   </div>
 *   <div data-autoheight-group="wrapper">
 *     <div data-autoheight-group="title">
 *       <h1>item 2</h1>
 *       <h2>Sub title</h2>
 *     </div>
 *     content
 *   </div>
 * </div>
 *
 * @example
 * <div data-autoheight-min-window-width="960">content</div>
 */
;(function ($) {
    $.fn.autoHeightItems = function (options) {
        let settings = $.extend($.fn.autoHeightItems.defaults, options);

        return this.each(function () {
            let $this = $(this);
            let autoHeight = function () {
                /**
                 * create a hash of all items that have a autoheight and group them together in a nested array by their containers => [groups] => [items]
                 */
                let elementTree = {};
                let windowWidth = $(window).width();

                $this.find(settings.selector).each(function () {
                    const $elm = $(this);
                    let minWidth = $elm.data("autoheight-min-window-width");
                    if ((minWidth !== undefined && parseInt(minWidth) > 0 && windowWidth < parseInt(minWidth)) || (minWidth === undefined && windowWidth < settings.minWindowWidth)) {
                        $elm.css("height", "auto");
                        return true;
                    }
                    let groupName = $elm.data(settings.dataGroupName);

                    if (groupName === undefined || groupName.length === 0) {
                        let $group = $elm.closest("[data-autoheight-group]");
                        if ($group === undefined || $group.length === 0) {
                            groupName = "_defaultGroup";
                        } else {
                            groupName = $group.data("autoheight-group");
                        }
                    }

                    const method = $elm.data("autoheight-method");

                    let thisHeight = 0;
                    if (method === "outer") {
                        thisHeight = $elm.css("height", "auto").outerHeight();
                    } else {
                        thisHeight = $elm.css("height", "auto").height();
                    }

                    let containerName = '_defaultContainer';

                    // check if we have a container defined in the parent DOM tree
                    let $container = $elm.closest("[data-autoheight-container]");
                    if ($container.length > 0) {
                        containerName = $container.data("autoheight-container");
                    } else {
                        // let's see if the item resides within a row since that is also a logical way to group items
                        // to autoheight together
                        $container = $elm.closest(".row");
                        if ($container.length > 0) {
                            // if the row does not have an #id set, we'll assign a random one so we can group items within it together
                            if ($container.prop('id').length === 0) {
                                $container.prop('id', 'row-' + uuidv4());
                            }
                            containerName = $container.prop('id')
                        }
                    }

                    // if this is a new container add it to the elementTree
                    if (!elementTree.hasOwnProperty(containerName)) {
                        elementTree[containerName] = {
                            '_defaultGroup': {
                                maxHeight: 0,
                                elements: []
                            }
                        }
                    }
                    // if we haven't seen this groupName inside this container yet create the required items
                    if (!elementTree[containerName].hasOwnProperty(groupName)) {
                        elementTree[containerName][groupName] = {
                            maxHeight: 0,
                            elements: []
                        };
                    }
                    // add our current element to the array
                    elementTree[containerName][groupName].elements.push($elm);

                    // check if we need to update our maxHeight for this container / group combo
                    if (elementTree[containerName][groupName].maxHeight < thisHeight) {
                        elementTree[containerName][groupName].maxHeight = parseInt(thisHeight);
                    }
                });

                // at this point we have built our tree and added all our elements / heights to it.
                // now we just need to loop through each container / group / elements and set their heights
                for (let container in elementTree) {
                    if (elementTree.hasOwnProperty(container)) {
                        // now the groups inside that container
                        for (let group in elementTree[container]) {
                            if (elementTree[container].hasOwnProperty(group)) {
                                if (elementTree[container][group].elements.length > 0) {
                                    for (let element in elementTree[container][group].elements) {
                                        if (elementTree[container][group].elements.hasOwnProperty(element)) {
                                            elementTree[container][group].elements[element].height(elementTree[container][group].maxHeight);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            };
            let delayedResize = function () {
                $(window).trigger("resize");
            };
            let uuidv4 = function() {
                return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            };
            autoHeight();
            $(window).on("resize.autoheightitems orientationchange.autoheightitems", autoHeight);
            $(window).on("load", delayedResize);
        });
    };
    $.fn.autoHeightItems.defaults = {
        selector: "[data-autoheight]",
        minWindowWidth: 992,
        dataGroupName: "autoheight",
    };
    $("body").autoHeightItems();
})(jQuery);